
import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import { LabelColour, ButtonColour } from 'src/utils/ColourUtilities'
import GetAny from 'src/utils/GetAny'
import { Link } from 'gatsby'
import FluidImage from 'src/utils/FluidImage'
import Button from 'src/components/Button'
import resolveLink from 'src/utils/resolveLink'

const GridWithOffsetIcons = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`py-20 lg:py-32 px-6 ${blok.lightgray_background ? 'bg-lightgrey' : 'bg-white'}`}>
                <div className="max-w-7xl mx-auto">
                    <div className="flex lg:flex-row flex-col lg:justify-between lg:items-end">
                        <div>
                            <Label className={`${LabelColour(blok.colour)} mb-0 font-bold`}>{blok.label}</Label>
                            <Heading size="h2" className="font-medium lg:mb-0">{blok.title}</Heading>
                        </div>
                        {blok.button_text && 
                            <Button to={blok.button_target.cached_url} className={`float-right lg:self-auto self-start pr-0 ${ButtonColour(blok.colour)}`}>{blok.button_text}</Button>
                        }
                    </div>
                    <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 gap-14">
                        {GetAny(blok.items).map((item) => (
                            <Link to={resolveLink(item.full_slug)} key={item.uuid} className="col-span-1 group">
                                <div className="relative group-hover:-translate-y-2 duration-300">
                                    <FluidImage image={item.content.featured_image} className="w-full"/>
                                    {item.tag_list.includes('New') && 
                                        <div className="bg-[#D83A90] md:w-16 w-12 md:h-16 h-12 text-white font-medium md:text-sm text-xs flex items-center justify-center rounded-full absolute md:top-5 top-4 md:right-5 right-4">NEW</div>
                                    }
                                </div>
                                <Heading size="h4" className="font-medium mb-3 mt-5">{item.name}</Heading>
                                <p className="font-light lg:max-w-sm leading-tight">{item.content.description}</p>
                                <Button className="group-hover:text-orange mt-2" iconClass="ml-0"/>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default GridWithOffsetIcons
  