import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import SbEditable from "storyblok-react"

const googleMapStyles = require("./GoogleMapStyles.json")

const containerStyle = {
  width: "100%",
  height: "545px",
}

const center = {
  lat: 51.52321295067492,
  lng: -0.1374481624779729,
}

const position = {
  lat: 51.52321295067492,
  lng: -0.1374481624779729,
}

const GoogleMaps = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
        <LoadScript preventGoogleFontsLoading googleMapsApiKey="AIzaSyCojalgf3Ff7vrjGMxjan_o-aNe5vhWeMA">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            options={{ 
              styles: googleMapStyles,
              mapTypeControl: false,
              streetViewControl: false,
            }}
          >
            <Marker
              position={position}
              icon={{
                url:
                  "https://a.storyblok.com/f/138741/x/e5a97ab912/map_marker.svg",
              }}
            />
          </GoogleMap>
        </LoadScript>
    </SbEditable>
  )
}

export default GoogleMaps
