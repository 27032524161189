import React from 'react'
import SbEditable from 'storyblok-react'
import FluidImage from 'src/utils/FluidImage'
import Hexagons from "src/images/hexagon-white-bottom.svg"

const FullWithImage = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 sm:py-32">
                    <div className="relative">
                        <FluidImage image={blok.image} className=""/>
                        {blok.hexagons && 
                            <Hexagons className="opacity-60 absolute bottom-0 right-0 scale-x-[-1] w-[400px]"/>
                        }
                    </div>
                </div>
            </div>        
        </SbEditable>
    )
}

export default FullWithImage
