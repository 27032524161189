import React, { useRef, useState } from 'react'
import SbEditable from 'storyblok-react'
import FluidImage from 'src/utils/FluidImage'
import PlayButton from "src/images/play_button.svg"

const Video = ({ blok }) => {

    const vidRef = useRef(false);
    const [hide, setHide] = useState(false);
    const handlePlayVideo = () => {
        vidRef.current.play()
        setHide(true)
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="relative bg-white py-16 sm:py-20 px-6">
                <div className="relative max-w-7xl mx-auto">
                    <div className="relative z-10">
                        <video src={blok.cdn_video ? blok.cdn_video : blok.video.filename} type="video/mp4" preload="auto" controls width="1920" height="1080" muted controlsList="nodownload" ref={vidRef} className="z-10 block w-full h-full object-cover">
                            Sorry, your browser doesn't support embedded videos.
                        </video>
                    </div>
                    <div onClick={handlePlayVideo} onKeyDown={handlePlayVideo} role="button" tabIndex="0" className={`absolute block z-10 top-0 left-0 w-full h-full object-cover cursor-pointer transition-all duration-500 ease-in-out focus:outline-none outline-none ${hide ? 'opacity-0 invisible' : 'opacity-100 visible'}`}>
                        <FluidImage className="z-10 block w-full h-full object-cover" image={blok.thumbnail} />
                        <div className={`absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:h-24 h-16 sm:w-24 w-16 rounded-full inset-0 text-white`}>
                            <PlayButton className="w-full h-full"/>
                        </div>
                    </div>
                </div>
            </div>        
        </SbEditable>
    )
}

export default Video