import React from "react";
import SbEditable from "storyblok-react";
import Slider from "react-slick";
import RichText from "src/utils/RichText";
import HexagonsTop from "src/images/hexagon-white-top.svg"
import HexagonsBottom from "src/images/hexagon-white-bottom.svg"
import NavArrowPrev from "src/images/nav_arrow_prev.svg"
import NavArrowNext from "src/images/nav_arrow_next.svg"
import { LabelColourDarker, BackgroundColour } from 'src/utils/ColourUtilities'

const TestimonialsSimpleCentered = ({ blok }) => {

  function ArrowPrev({ className, onClick }) {
    return (
      <div 
        className={`${className.includes('slick-disabled') ? LabelColourDarker(blok.background_colour) : 'text-white'} block absolute bottom-0 right-1/2 before:hidden cursor-pointer z-50 px-3 top-full`} 
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex="0"
      >
          <NavArrowPrev width="20" height="20" />
      </div>
    );
  }
  function ArrowNext({ className, onClick }) {
    return (
      <div 
        className={`${className.includes('slick-disabled') ? LabelColourDarker(blok.background_colour) : 'text-white'} block absolute bottom-0 left-1/2 before:hidden cursor-pointer z-50 px-3 top-full`} 
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex="0"
      >
          <NavArrowNext width="20" height="20" />
      </div>
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    prevArrow: <ArrowPrev/>,
    nextArrow: <ArrowNext/>
  };

  return (
    <SbEditable content={blok} key={blok._uid}>
      <section className={`relative pt-20 pb-24 overflow-hidden ${BackgroundColour(blok.background_colour)}`}>
        <HexagonsTop className="absolute top-0 right-0 hidden sm:block w-[500px]"/>
        <HexagonsBottom className="absolute bottom-0 left-0 hidden sm:block w-[500px]"/>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Slider {...settings}>
            {blok.items?.map((item) => (
              <div>
                <div className="block max-w-4xl m-auto text-center">
                  <p className={`uppercase text-[13px] sm:text-[14px] font-bold ${LabelColourDarker(blok.background_colour)} mb-9 tracking-[0.075em]`}>{blok.label ? blok.label : 'What our clients say'}</p>
                  <blockquote className="font-light text-[20px] sm:text-[26px] leading-6 sm:leading-8 mb-10">
                    <RichText>{item.quote}</RichText>
                  </blockquote>
                  <p className="text-sm text-[16px] font-light mb-10">
                    <span className="font-bold">{item.author} </span>
                    {item.role}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </SbEditable>
  );
};

export default TestimonialsSimpleCentered;
