import React from 'react'

const FormField = ({containerClass, id, label, required, type, autoComplete, placeholder, value}) => {
    return (
        <div className={containerClass}>
            <label htmlFor={id} className="block font-light text-black">
                {label}
                {required && <span className="text-orange">*</span>}
            </label>
            <div className="mt-1">
                {type !== 'textarea' &&
                    <input
                        type={type}
                        name={id}
                        id={id}
                        required={required ? true : false}
                        autoComplete={autoComplete}
                        placeholder={placeholder}
                        defaultValue={value ? value : ''}
                        className="py-3 px-4 block w-full placeholder-gray-300 text-black focus:ring-transparent focus:border-orange border-gray-200"
                    />
                }
                {type === 'textarea' &&
                    <textarea
                        id={id}
                        name={id}
                        rows={8}
                        placeholder={placeholder}
                        className="py-3 px-4 block w-full placeholder-gray-300 text-black focus:ring-transparent focus:border-orange border-gray-200"
                        defaultValue={value ? value : ''}
                    />
                }
            </div>
        </div>
    )
}

export default FormField