import React, { useState } from 'react'
import FluidImage from '/src/utils/FluidImage'
import Heading from '../Heading'
import WithLargeImagesPopup from './WithLargeImagesPopup'
import Button from 'src/components/Button'

const WithLargeImagesTeamMember = (item) => {

    const [open, setOpen] = useState(false)
    function handleSetOpen() {
        setOpen(!open)
    }

    const hasCopy = item.content.popup_copy && item.content.popup_copy.length > 0

    return (
        <div key={item.name}>
            <div className={`group outline-none focus:outline-none ${hasCopy ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => setOpen(true)} onKeyPress={() => setOpen(true)} role="button" tabIndex="0">
                <div className="space-y-4">
                    <div className="relative">
                        <FluidImage image={item.content.image} className={`object-cover z-10 opacity-100 group-hover:opacity-0 duration-300`}/>
                        <FluidImage image={item.content.hover_image} className={`object-cover w-full h-full !absolute top-0 left-0`}/>
                    </div>
                    <div className="flex flex-row items-start justify-between">
                        <div>
                            <Heading size="h5" className="!mb-1 !leading-none group-hover:text-orange duration-300 md:!text-xl !text-base font-medium">{item.name}</Heading>
                            <p className="text-[#9C9C9C] text-base font-light leading-none">{item.content.job_title}</p>
                        </div>
                        {hasCopy && <Button className="group-hover:text-orange duration-300 !p-0 md:mt-1.5" iconClass="ml-0"/>}
                    </div>
                </div>
            {hasCopy && <WithLargeImagesPopup {...item} open={open} handleSetOpen={handleSetOpen}/>}
            </div>
        </div>
    )
};

export default WithLargeImagesTeamMember