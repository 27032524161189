import React, { useContext } from 'react'
import SbEditable from 'storyblok-react'
import Heading from 'src/components/Heading'
import RichText from 'src/utils/RichText'
import BackButton from 'src/components/BackButton'
import { PageContext } from 'src/utils/context'
import FluidImage from 'src/utils/FluidImage'
import TagsAndDate from 'src/components/TagsAndDate'
import Share from 'src/components/Share'
import GatedContent from 'src/components/GatedContent'

const ContentCentered = ({ blok }) => {
    const context = useContext(PageContext)
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white relative max-w-7xl mx-auto">
                <div className="py-16 lg:py-24 px-6">
                    {!blok.disable_back_button && <BackButton component={context.component}/>}
                    <TagsAndDate tags={blok.disable_tags ? null : context.tag_list} date={context.first_published_at}/>
                    <Heading size='h1' className={`lg:!text-5xl !text-2xl !font-light max-w-3xl lg:!leading-tight !leading-tight !mb-10`}>{context.name}</Heading>
                    {!blok.disable_featured_image && <FluidImage image={context.featured_image} className="max-w-3xl mb-10"/>}
                    <div className="prose lg:text-lg text-base lg:leading-normal font-light">
                        <RichText>{blok.content}</RichText>
                    </div>
                    { context.attachment && <GatedContent document={context.attachment} /> }
                </div>  
                {!blok.disable_share && <Share currentUrl={context.full_slug} title={context.name}/>}
            </div>
        </SbEditable>
    )
}

export default ContentCentered