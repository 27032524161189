import React from 'react'
import ButtonArrow from 'src/images/button_arrow.svg'

const FormSubmitButton = ({form}) => {
    return (
        <div className="sm:col-span-2">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value={form} />                                
            <button
                type="submit"
                className="group inline-flex justify-center items-center font-medium lg:text-xl text-lg !leading-6 cursor-pointer pr-6 py-3 line-crop ease-in-out duration-300 tracking-[-1px] text-orange group-hover:text-darkorange"
            >
                Submit
                <ButtonArrow className={`w-[12px] transition-transform duration-300 ml-2 group-hover:rotate-45 fill-current`}/>
            </button>
        </div>
    )
}

export default FormSubmitButton