import { graphql, useStaticQuery } from 'gatsby';

// Set autoPullItems enabled by default
const GetNewsArticles = ( items, autoPullItems = false ) => {

  let filteredPosts = [];
  const isResolved = typeof items[0] !== 'string'
  
  const data = useStaticQuery(graphql`
    {
      posts: allStoryblokEntry(
        filter: {field_component: {eq: "news_article"}}
        sort: {order: DESC, fields: first_published_at}
        ) {
        edges {
          node {
            id
            uuid
            name
            full_slug
            content
            tag_list
            first_published_at
          }
        }
      }
    }
  `)

  // If none are selected in storyblok it will grab all
  if(!isResolved || items.length === 0) {
    if(items.length > 0) {
      // This will also sort the items as they are in storyblok
      filteredPosts = items.map((selected_uuid) => data.posts.edges.find((post) => post.node.uuid === selected_uuid)).filter(n=>n!==undefined);
    } else {
      // Check if autoPullItems is enabled
      filteredPosts = autoPullItems ? data.posts.edges : []
    }

    filteredPosts = filteredPosts.map((p, i) => {
      if (autoPullItems && i < autoPullItems) {
        const content = p.node.content
        const newContent = typeof content === 'string' ? JSON.parse(content) : content
        p.node.content = newContent
        return p.node
      } return null
    })
  }
     
  return items = isResolved && items.length !== 0 ? items : filteredPosts

}

export default GetNewsArticles