import React from "react";
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import ButtonArrow from "src/images/button_arrow.svg"
import { Link } from "gatsby"

const GatedContent = ({ document }) => {
    const identity = useIdentityContext()
    const roles = identity?.user?.app_metadata?.roles || []

    if( roles.includes('admin') || roles.includes('member') ) {
        return (
            <a className="group text-2xl flex duration-300 text-orange hover:text-darkorange mt-10" href={document} download>        
                Download the presentation 
                <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 ml-2 mt-2 fill-current" width="18" height="19" />
            </a> 
        )
    } else {
        return (
            <Link to="/login/" className="group block cursor-pointer mt-10">
                <div className="text-sm font-light">     
                    Content for members        
                </div>
                <div className="text-3xl flex duration-300 text-green group-hover:text-darkgreen">        
                    Login               
                    <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 ml-2 mt-2 fill-current" width="18" height="19" />
                </div> 
            </Link>            
        )
    }

}

export default GatedContent