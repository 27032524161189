
import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import Button from 'src/components/Button'
import { LabelColour } from 'src/utils/ColourUtilities'
import GetNewsArticles from 'src/utils/GetNewsArticles'
import ArchiveItem from 'src/components/Archives/ArchiveItem'

const ThreeColumnCards = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`py-20 lg:py-32 bg-white`}>
                <div className="max-w-7xl mx-auto px-6">
                    <div className="flex lg:flex-row flex-col lg:justify-between lg:items-end">
                        <div>
                            <Label className={`${LabelColour(blok.colour)} !mb-0 font-bold`}>{blok.label}</Label>
                            <Heading size="h2" className="font-medium lg:mb-0">{blok.title}</Heading>
                        </div>
                        {blok.button_text && 
                            <Button to={blok.button_target.cached_url} className={`float-right lg:self-auto self-start pr-0 text-orange hover:text-darkorange`}>{blok.button_text}</Button>
                        }
                    </div>
                    <div className="lg:mt-8 mt-4 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 gap-12">
                        {GetNewsArticles(blok.items, 3).map((item, index) => (
                            item && <ArchiveItem {...item} key={index}/>
                        ))}
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default ThreeColumnCards
  