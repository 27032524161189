import React, { useState, useEffect, useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'
import Heading from 'src/components/Heading'
import Button from 'src/components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Accordion = (props) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <Disclosure as="div" className="pt-4">
      {({ open }) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="group text-left w-full flex justify-between items-start">
              <Heading
              size="h3"
                className={classNames(
                  open ? "text-orange" : "text-black",
                  "font-medium group-hover:text-orange mb-0 duration-300"
                )}
              >
                {props.name}
              </Heading>
              <span className="h-7 flex items-center">
                <FontAwesomeIcon 
                  icon={open ? faMinus : faPlus}
                  className={classNames(
                    open
                      ? "text-orange "
                      : "text-black ",
                    "h-6 w-6 transform transition-all duration-300 ease-in-out group-hover:text-orange"
                  )}
                />
              </span>
            </Disclosure.Button>
          </dt>
          <dd className="mt-2 border-b">
            <div
              style={{ maxHeight: open ? height+65 : 0 }}
              className={classNames(
                open ? "mb-4" : "mb-0",
                "relative overflow-hidden transform transition-all duration-500 ease-in-out"
              )}
            >
              <div
                className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4"
                ref={ref}
              >
                {props.items.map((item) => (
                  <Link to={resolveLink(item.full_slug)} className="group block" key={item.uuid}>
                      <Heading size="h4" className="font-medium lg:!text-xl !text-xl max-w-[300px] group-hover:text-orange duration-300">{item.name}</Heading>
                      <div className="max-w-[300px] mb-5 font-light">
                        {item.content.description}
                      </div>
                      <Button className="!p-0 group-hover:text-orange" iconClass="ml-0"/>
                  </Link>
                ))}
              </div>
            </div>
          </dd>
        </>
      )}
    </Disclosure>
  );
};


export default Accordion;
