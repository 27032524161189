import React from "react";
import SbEditable from "storyblok-react";
import FluidImage from "src/utils/FluidImage";

const ThreeColumnImage = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="bg-white px-6">
        <div className="relative max-w-7xl mx-auto py-16 sm:py-28">
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-3 lg:gap-4 lg:max-w-none">
            <div className="py-2">
             <FluidImage className="object-cover object-center" image={blok.image_one}/>
            </div>
            <div className="py-2">
              <FluidImage className="object-cover object-center" image={blok.image_two}/>
            </div>
            <div className="py-2">
              <FluidImage className="object-cover object-center" image={blok.image_three}/>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default ThreeColumnImage;