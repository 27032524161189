
import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import RichText from 'src/utils/RichText'
import Button from 'src/components/Button'
import GetSettings from 'src/utils/GetSettings'

const SimpleFourColumn = ({blok}) => {

    const settings = GetSettings()

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`py-12 px-6 bg-lightgrey`}>
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-20">
                        <div>
                            <Label className="text-orange">Call Us</Label>
                            <a href={`tel:${settings.telephone_number}`} className="font-light text-sm hover:text-orange duration-300 cursor-pointer tracking-tight">{settings.telephone_number}</a>
                        </div>
                        <div>
                            <Label className="text-orange">Email Us</Label>
                            <a href={`mailto:${settings.email}`} className="font-light text-sm hover:text-orange duration-300 cursor-pointer tracking-tight">{settings.email}</a>
                        </div>
                        <div>
                            <Label className="text-orange">Find Us</Label>
                            <span className="font-light block max-w-[150px] text-sm tracking-tight">{settings.address}</span>
                            <Button to={'https://goo.gl/maps/xHsZSkPdDccn657LA'} target='_blank' className="!text-sm !p-0 hover:text-orange">
                                <span className="font-light mr-1">Open in </span> Google Maps
                            </Button>
                        </div>
                        <div>
                            <Label className="text-orange">Opening Hours</Label>
                            <div className="font-light text-sm tracking-tight">
                                <RichText>{settings.opening_hours}</RichText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default SimpleFourColumn
  