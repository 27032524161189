import React from 'react'
import SbEditable from 'storyblok-react'
import GetCareers from 'src/utils/GetCareers'
import Accordion from './Accordion'

const FourByTwoGridOnBrandV2 = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white py-14 px-6 lg:pb-20">
                <div className="max-w-7xl mx-auto">
                    <Accordion items={GetCareers('en')} name="London Office"/>
                    <Accordion items={GetCareers('de')} name="German Office"/>
                </div>
            </div>
      </SbEditable>
    )
  };

export default FourByTwoGridOnBrandV2
  