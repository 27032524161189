import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from 'src/utils/RichText'
import FluidImage from 'src/utils/FluidImage'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import { LabelColourDarker, BackgroundColour } from 'src/utils/ColourUtilities'

const SplitWithImage = ({ blok }) => {
    return (
        <SbEditable content={blok}>
            <div className={`relative ${BackgroundColour(blok.colour)}`}>
                <div className="mx-auto flex lg:flex-row flex-col items-center xl:py-32 lg:py-20">
                    <div className={`flex-1 lg:w-1/2 w-full ${blok.flip ? 'lg:order-last' : ''}`}>
                        <FluidImage image={blok.image} className=""/>
                    </div>
                    <div className={`relative lg:w-1/2 w-full lg:py-0 py-12 lg:px-0 px-6 ${blok.flip ? 'lg:pl-16 xl:pr-24 lg:pr-12' : 'lg:pr-6 xl:pl-24 lg:pl-12'}`}>
                        <Label className={`font-bold ${LabelColourDarker(blok.colour)}`}>{blok.label}</Label>
                        <Heading size="h3" className="text-current !tracking-[-1px]">{blok.title}</Heading>
                        <div className="!text-current mt-5 prose lg:max-w-lg max-w-none font-light lg:text-xl text-lg">
                            <RichText>{blok.content}</RichText>
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default SplitWithImage