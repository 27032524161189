import React from 'react'
import { Link } from 'gatsby'
import ButtonArrow from 'src/images/button_arrow.svg'

const BackButton = ({component}) => {
    const link = 
        component === "news_article" ? '/news/' :
        component === "career" ? '/careers/' :
        component === "insight" ? '/insights/' :
        ''
    const buttonText = 
        component === "news_article" ? 'See all news' :
        component === "career" ? 'See all careers' :
        component === "insight" ? 'Back to insights' :
        ''
    return (
        <Link 
          className={`lg:mb-24 mb-8 group inline-flex justify-center items-center !font-medium text-base !leading-6 cursor-pointer pr-6 py-3 line-crop ease-in-out duration-300 text-orange hover:text-darkorange`}
          to={link}
          >
            <ButtonArrow className={`w-[10px] transition-transform duration-300 mr-2 rotate-[225deg] fill-current`}/>
            {buttonText}
        </Link>
    )
}

export default BackButton