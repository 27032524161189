import React from 'react'
import SbEditable from "storyblok-react"
import FluidImage from 'src/utils/FluidImage'
import Heading from 'src/components/Heading'
import ButtonArrow from 'src/images/button_arrow.svg'
import Hexagons from "src/images/hexagon-white-bottom.svg"

const Hero = ({ blok }) => {

    const isVideo = blok.desktop_asset?.filename?.includes(".mp4") && blok.mobile_asset?.filename?.includes(".mp4")

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="relative h-[80vh] overflow-hidden">
                <div className="absolute inset-0">
                    {!isVideo && 
                        <>
                            <FluidImage className="w-full h-full object-cover lg:block hidden" image={blok.desktop_asset}/>
                            <FluidImage className="w-full h-full object-cover lg:hidden block" image={blok.mobile_asset}/>
                        </>
                    }
                    {isVideo && 
                        <div className="block w-full h-full object-cover">
                            <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className="lg:block hidden w-full h-full object-cover">
                                <source src={blok.desktop_asset.filename} type="video/mp4"/>
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className="lg:hidden block w-full h-full object-cover">
                                <source src={blok.mobile_asset.filename} type="video/mp4"/>
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </div>
                    }
                    {!blok.disable_backdrop && 
                        <>
                            <div className="absolute inset-0 bg-black opacity-50 mix-blend-multiply" aria-hidden="true"></div>
                            <Hexagons className={`absolute opacity-50 bottom-0 right-0 w-[600px] scale-x-[-1]`}/>
                        </>
                    }
                </div>
                <div className="relative max-w-7xl mx-auto px-6 h-full flex flex-col justify-center items-start">
                    <Heading size="h1" className="text-white max-w-4xl">{blok.title}</Heading>
                    {!blok.disable_backdrop && 
                        <ButtonArrow className="text-white rotate-[135deg] absolute bottom-8"/>
                    }
                </div>
            </div>
        </SbEditable>
    )
}

export default Hero
