
import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import RichText from 'src/utils/RichText'
import { LabelColour } from 'src/utils/ColourUtilities'
import GetTeam from '/src/utils/GetTeam';
import WithLargeImagesTeamMember from './WithLargeImagesTeamMember'

const WithLargeImages = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`py-14 lg:py-28 px-6 bg-white`}>
                <div className="max-w-7xl mx-auto">
                    <Label className={`${LabelColour(blok.colour)} mb-0`}>{blok.label}</Label>
                    <Heading size="h3" className="font-medium">{blok.title}</Heading>
                    <div className="prose max-w-prose lg:text-xl text-lg font-light lg:mt-8 mt-4">
                        <RichText>{blok.description}</RichText>
                    </div>
                    <div className="lg:mt-20 mt-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8">
                        {GetTeam().map((item) => (
                            <WithLargeImagesTeamMember {...item} key={item.uuid}/>
                        ))}
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default WithLargeImages
  