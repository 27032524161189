
import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import RichText from 'src/utils/RichText'
import FluidImage from 'src/utils/FluidImage'
import GetSettings from 'src/utils/GetSettings'

const ThreeColumnCardsStatic = ({blok}) => {

    const settings = GetSettings()

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`py-20 lg:py-32 px-6 bg-white`}>
                <div className="max-w-7xl mx-auto">
                    <div className="flex lg:flex-row flex-col lg:justify-between lg:items-end">
                        <div>
                            <Label className={`text-orange mb-0`}>{settings.awards_label}</Label>
                            <Heading size="h2" className="font-medium lg:mb-0">{settings.awards_title}</Heading>
                            <div className="prose text-xl max-w-prose mt-5 font-light tracking-tight">
                                <RichText>{settings.awards_description}</RichText>
                            </div>
                        </div>
                    </div>
                    <div className="lg:mt-16 mt-8 grid grid-cols-1 lg:grid-cols-3 lg:gap-4 gap-12">
                        {settings.awards.map((item) => (
                            <div className="col-span-1 group" key={item._uid}>
                                <FluidImage image={item.image} className="w-full"/>
                                <Heading size="h4" className="font-medium mb-1 mt-4">{item.title}</Heading>
                                <div className="font-light lg:max-w-sm">
                                    <RichText>{settings.description}</RichText>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default ThreeColumnCardsStatic
  