import React from "react";
import SbEditable from "storyblok-react";
import Link from "gatsby-link";
import Heading from 'src/components/Heading'
import FluidImage from 'src/utils/FluidImage'
import ButtonArrow from 'src/images/button_arrow.svg'
import resolveLink from 'src/utils/resolveLink'
import Hexagons from "src/images/hexagon-white-top.svg"

const SimpleJustified = ({ blok }) => {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <Link to={resolveLink(blok.target?.cached_url)} className="block relative w-full group">
            <div className="absolute inset-0">
                <FluidImage className="w-full h-full object-cover" image={blok.background_image}/>
                <div className="absolute inset-0 bg-black group-hover:opacity-0 opacity-60 mix-blend-multiply duration-300 overflow-hidden" aria-hidden="true"/>
                <Hexagons className="absolute top-0 right-0 w-[600px] group-hover:opacity-0 duration-300"/>
            </div>
            <div className="relative max-w-7xl mx-auto px-6 lg:py-36 py-24">
                <Heading size="h1" className="text-white text-6xl sm:!mb-3 !mb-6">{blok.title}</Heading>
                <ButtonArrow className="text-white group-hover:rotate-45 duration-300" width="32" height="32"/>
            </div>
        </Link>
      </SbEditable>
    );
  };
  
  export default SimpleJustified;
