import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import RichText from 'src/utils/RichText'
import { LabelColour } from 'src/utils/ColourUtilities'

const FourByTwoGridOnBrand = ({blok}) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white pt-16 pb-20 px-6 lg:pt-24 lg:pb-28">
                <div className="max-w-7xl mx-auto">
                    <Label className={`${LabelColour(blok.colour)}`}>{blok.label}</Label>
                    <Heading size="h2" className="font-medium">{blok.title}</Heading>
                    <div className="lg:mt-16 mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                        {blok.items.map((item, index) => {
                            const number = index > 8 ? index+1 : `0${index+1}` 
                            return (
                                <div className="col-span-1 border-t border-[#E2E2E2]" key={index}>
                                    <span className={`block font-medium my-4 text-5xl ${LabelColour(blok.colour)}`}>{number}</span>
                                    <Heading size="h4" className="font-medium !mb-2 lg:!text-xl">{item.title}</Heading>
                                    <div className="prose font-light text-base">
                                        <RichText>{item.description}</RichText>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
      </SbEditable>
    )
  };

export default FourByTwoGridOnBrand
  